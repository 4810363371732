<!--
   
(decimal y entero en desuso, USAR MASK (se puede seguir usando, pero usar MASK)

req: requerido

uCase: mayúscula

format: cif/banco/money
format: en schema: $money, $cif, $banco

mask: -###.##, ###, -#, #.#, -#.# cualquier combinación de hasta dos decimales y signo
mask en schema: -###.## igual que fuera de esquema, no tiene prefijo, es #



max: alfabetico o numérico
min: alfabetico o numérico
max/mimx en schema: @0|10

maxlength: máximo número de caracteres
minlength: mínimo número de caracteres

maxlength/minlength en schema %5|2

-->

<template>
<v-tooltip
  v-model="show"
  color="error"
  top>
            
    <template v-slot:activator="{on}">    
      <v-text-field
        ref="input"    
        v-bind='{...$attrs, ...$input}'
        @input.native="onInput"
        :style="styleComp"
        :value="displayValue"
        :label='$label'
        :error="$error"      
        @focus="onFocus"
        @blur="onBlur"
        @keydown="onKeyDown"
        @keypress="onKeyUp"
        @change="onChange"
        @click:append="onAppend"
        :maxlength='$maxlength'
      >

          <template v-slot:label="">            
            <span v-if="$error" v-on="on">{{$label}}</span>            
          </template>
      </v-text-field>
    </template>
            
    <span>{{ tooltipErrors }}</span>
</v-tooltip>   
</template>



<script>

  import Vue from "vue";  
  import { mixSchValidate } from "@/mixins/mixSchValidate";
  export default {

    mixins: [ mixSchValidate],
    props: {
      ct: { type: [Array, Object], default: null },
      styleComp: { type: String, default: "" },
      value: { type: [String, Number], default: "" },
      label: { type: String, default: "" },
      format: { type: [String], default: "" },
      mask:{ type: [String], default: "" },
      ndecimals: { type: Number, default: 2 },
      negativo: { type: Boolean, default: true },
      chars:{ type: [String], default: "" },
      simbolo: { type: String, default: "" },
      maxlength:{ type:Number, default:0 },
      minlength:{ type:Number, default:0 },

      min: { type: [String, Number], default: "" },
      max: { type: [String, Number], default: "" },

      required: { type: Boolean, default: false },
      uCase: { type: Boolean, default: false },

      fntKey: { type:Function, default: null } // fn a ejecutar en el keydown
    },

    data() {
      return {
        newValue: "",
        errors: false,
        show:false, // tooltip show
        enfocado:false
      };
    },


    methods: {
    onFocus() {
        console.log("                  onFocus              ");
        console.log("ct....................................",this.ct);
        console.log("ct[3].................................",this.ct && this.ct[3]?this.ct[3].join(''):'no ct');
        console.log("label.................................",this.$label);
        console.log("format................................",this.format);
        console.log("$format...............................",this.$format);
        console.log("$uCase................................",this.$uCase);
        console.log("$mask.................................",this.$mask);
        console.log("$maskNumber...........................",this.$maskNumber);
        console.log("$maxlength............................",this.$maxlength);
        console.log("$minlength............................",this.$minlength);
        console.log("$max..................................",this.$max);
        console.log("$min..................................",this.$min);
        console.log("$simbolo..............................",this.$simbolo);
        console.log("$entero...............................",this.$entero);
        console.log("$decimal..............................",this.$decimal);
        console.log("$chars................................",this.$chars);
        console.log("ct[2].................................",!this.ct?this.value:this.ct[2]);
        console.log("newValue..............................",this.newValue);
        console.log("iniValue..............................",this.iniValue);
        console.log("                                ");

        // valor almacenado en value o en ct[2]
        this.newValue=this.iniValue;

        // switch de foco obtenido
        this.enfocado= true;
      },


      // ejecuta fn 'fntKey' en cada pulsación de keydown (si se ha recibido alguna)
      // por normativa, si la fn recibe un valor, se asigna ese valor al value y se emite un 'blur'
      onKeyDown(e) {       
        if (!this.fntKey) return;        
        let val= this.fntKey(e);

        if (val== null || val==undefined) return;
        this.newValue= e.target.value= val;
        e.target.blur();
      },


      onKeyUp(e) {      
        // check chars permitidos
        if (this.$chars && this.$chars.indexOf(e.key) === -1) {
          e.preventDefault();
          return;
        }
        if (this.$maskNumber) this.onKeyUpmaskNumber(e);
      },

      onKeyUpmaskNumber(e){      
        // check duplicados de signos negativo o coma decimal
        let newValue=this.newValue.toString();
        if (newValue.length==0) return;

        
        if (this.$decimal && 
            (e.key == "." || e.key == ",") &&
            (newValue.indexOf(".") != -1 || newValue.indexOf(",") != -1)
            ) {
          e.preventDefault();
          return;
        }
        // no permitir dos símbolos de negativo
        if (this.$entero && e.key == "-" && newValue.indexOf("-") != -1) {
          e.preventDefault();
          return;
        }
      },

      onInput(e) {             
        if (this.$maskNumber){
            this.onInputNumerico(e)
            return
        } 
        this.newValue=e.target.value;
        
      },

      onInputNumerico(e) {        
        let newVal = e.target.value;        
        newVal = newVal.replace(/,/g, ".");
    
        // LIMITAR MAXIMO NUMERO DE DECIMALES=this.decimales
        let numero = newVal.split(".");
        if (numero.length > 1) {
          if (numero[1].length > this.$decimal) {
            newVal = numero[0] + "." + numero[1].substr(0, this.$decimal);
          }
        }

        // CHEQUEAR SIGNO NEGATIVO (eliminar si no esta en la primera posición)
        if (this.negativo && newVal.trim().indexOf("-") > 0) newVal = newVal.replace("-", "");
      
        // ASIGNAR NUEVO VALOR A this.newValue
        this.newValue = e.target.value = newVal;
    
        if (Number.isNaN(Number.parseFloat(this.newValue)))
          console.log("no numerico");
        return;
      },

      onBlur() {       
        this.enfocado= false;

        // Mayuscular y limpiar de espacios el control si es string
        if (typeof this.newValue==='string') this.newValue=this.newValue.trim();
        if (this.$uCase ||
            this.$format==='cif'||
            this.$format==='banco' ) {

            this.newValue=this.newValue.toUpperCase();
        } 
        //
        
        //
        
        // NUMERICO
        if (this.$maskNumber) {
            if (Number.isNaN(Number.parseFloat(this.newValue))) this.newValue = 0;
            this.newValue = parseFloat(this.newValue);         
        }
        // //
        // //
        //  if (mantenerFoco){
        //   this.$refs.input.focus(); 
        //  return
        //  } 

        this.errors= this.errorsGet(this.newValue);
        console.log('ERRORES.....', this.errors);

        
        // pasar dato reactivo
        if (this.ct!=null) {
          Vue.set(this.ct,2, this.newValue)
        }
        this.$emit("input", this.newValue); 

        if (this.$listeners.blur) this.$listeners.blur();
      },
    
      onChange() {      
        if (this.$listeners.change) this.$listeners.change();  
      },

      onAppend() {
        this.$listeners.onAppend();
      },

      errorsGet(val) {     
        let arrError=new Array;   
             
        if (this.$maskNumber) val= Number(val);
        if  (this.$max              && val>this.$max) arrError.push('Valor máximo: '+this.$max);
        if  (this.$min              && val<this.$min) arrError.push('Valor mínimo:'+this.$min);
        if  (this.$format==='cif'   && (this.$required || val) && !this.$validar.validaCif(val))   arrError.push('CIF/NIF no valido');
        if  (this.$format==='banco' && (this.$required || val) && !this.$validar.validaBanco(val)) arrError.push('Código de cuenta no valido');
        if  (this.$format==='email' && (this.$required || val) && !this.$validar.validaEmail(val)) arrError.push('Email no válido');
        if  (this.$format==='url'   && (this.$required || val) && !this.$validar.validaUrl(val)) arrError.push('Dirección url no válida');
        if  (this.$minlength        && val.length<this.$minlength) arrError.push('Mínimo '+this.$minlength+' carácteres');
        if  (this.$maxlength        && val.length>this.$maxlength) arrError.push('Máximo '+this.$maxlength+' carácteres');
        if  (this.$required         && !val) arrError.push('Debe introducir un valor');
        if (this.$maskNumber        && isNaN(val)) arrError.push('Debe introducir un valor numérico');        
        return arrError;
      },


      

    },
    










  //--------------------------------------------------------------------------------------------------------------------------------------------------








    computed: {
      iniValue: function() {
        let val= this.ct!=null?this.ct[2]:this.value;
      
        // si es maskNumber pasamos a numérico para quitar los decimales en caso de .00
        if (this.$maskNumber && !isNaN(val))  return Number(val)
        return val;
      },

      displayValue: function() {
        // No maskNumber
        if (!this.$maskNumber) return this.iniValue;

        // maskNumber sin foco
        if (!this.enfocado) {          
          let t = Number(this.iniValue)
          .toFixed(this.$decimal)
          .toString();
          console.log('input...', t);
          
          return (t.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + this.$simbolo);
        }
    
        // maskNumber con foco
        return Number.isNaN(this.newValue) || Number(this.newValue)== 0? '' : this.newValue;
      },
      //-----------------------------------------------------------------------------------------
      //
    
      $label: function() {
        if (this.label!='')  return this.label;
        if (this.ct==null)   return '';
        return !this.ct[1]? '':this.ct[1];
      },
      $required:function(){
        if (this.required)    return true;
        if (this.ct==null)    return false;
        return this.ctProp('required',this.ct);
      },
      $format: function(){
        if (this.format==='' && this.ct==null)  return 'texto';
        if (this.format!='') return this.format;
        if (this.ct==null)   return '';
        return this.ctProp("format",this.ct);
      },
      $mask: function(){
        // mascaras numericas
        if (this.mask!='')  return this.mask;
        let mascara=this.ct!=null?this.ctProp("mask",this.ct):false;
        if (mascara) return mascara;
        // Si tiene $format sin mascara particular
        if (this.$format==='money')    mascara='#######.##';
        if (this.$format==='entero')   mascara='#######';
        if (this.$format==='decimal')  mascara='#######.##';
        return mascara;
      },

      $maskNumber: function(){
        return this.$mask?this.$mask.length:false;
      },

      $entero: function(){
        // devuelve NUMERO de posiciones de entero
        if (!this.$mask) return 0;
        return this.$mask.split('.')[0].length;
      },
      $decimal:function(){
        // devuelve NUMERO de posiciones decimales
        if (!this.$mask || this.$mask.indexOf('.')===-1) return 0;
        return this.$mask.split('.')[1].length;
      },
      $chars: function(){
        if (this.chars) return this.chars;
        let restringir=false;
        if (this.$entero) restringir='12345678790';
        if (this.$decimal) restringir+='.,';
        if (this.$maskNumber && this.$mask.indexOf('-')>-1) restringir+='-';
        return restringir;
      },
      $maxlength: function(){
        if (this.$maskNumber)  return this.$mask.length;
        if (this.maxlength>0)  return this.maxlength;
    
        if (this.$format==='cif')      return 9;
        if (this.$format==='banco')    return 24;
        if (this.ct!=null) {
            let ctlenght=this.ctProp('maxlength',this.ct)
            if (ctlenght) return ctlenght;
        }

        return 128;
      },

      $minlength:function(){
        if (this.minlength>0)  return this.minlength;
        if (this.ct!=null) {
            let ctlenght=this.ctProp('minlength',this.ct)
            if (ctlenght) return ctlenght;
        }
        return false;
      },
      $max(){
        if (this.max!='') return this.max;
        if (this.ct!=null) {
            let max=this.ctProp('max',this.ct)
            if (max) return max;
        }
        if (this.$maskNumber) return this.maskMaxValue(this.$mask)
        return false;

      },
      $min(){
        if (this.min!='') return this.min;
        if (this.ct!=null) {
              let min=this.ctProp('min',this.ct)
              if (min) return min;
        }
        return false;
      },
      $uCase(){
        if (this.uCase) return true;
        if (this.ct!=null) return this.ctProp('uCase',this.ct)
        return false;
      },
      
      $simbolo() {
        if (this.simbolo!='') return this.simbolo;
        if (this.$format === "money") return "€";
        return '';
      },

      $error() {
        if (this.errors.length) return true;
        return false;
      },

      tooltipErrors() {
        let txt= "";
        this.errors.forEach(error => {
          txt+=`${txt? '\n' : ''} ${error}`;
        });        

        return txt;
      }
     
    },


    watch: {      
      ct: {
        immediate: true,
        handler() {   
          // dependiendo si es un ct o un v-model                    
          let val= this.ct? this.ct[2] : this.value;

          // array de errores
          this.errors= this.errorsGet(val);
        }
      }   
    }
  }

</script>



